<template>
  <v-row
    class="mt-3"
    data-cy="bundles-card"
  >
    <v-col
      v-for="bundle of bundles"
      :key="`plugin-${bundle.easy_price_book_product_id}`"
      cols="12"
      sm="6"
      md="4"
      class="py-0 d-flex"
    >
      <v-card
        outlined
        class="d-flex flex-column"
        :class="{ 'mt-6': !bundle.highlighted }"
      >
        <v-card-title class="justify-center flex-grow-1">
          <div>{{ bundle.name }}</div>
          <div>{{ bundle.price }}</div>
          <div
            :class="{ 'mt-6': bundle.highlighted }"
          >
            <div class="text-center font-weight-thin mt-1 mb-3">
              <small>
                {{ $t('productDetails.perUserPerMonthBilledMonthly') }}
              </small>
            </div>
            <div>
              <v-switch
                :input-value="selectedBundleId === bundle.easy_price_book_product_id"
                inset
                class="mt-0 no-input-details pa-0 d-table mx-auto"
                :data-cy="`main-bundle-${bundle.id}`"
                @change="onBundleChange(bundle.easy_price_book_product_id)"
              />
            </div>
          </div>
        </v-card-title>
        <v-divider />
        <material-product-details-change-plan-bundles-groups :bundle-group-ids="bundle.bundleGroupsIds" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    bundleId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      selectedBundleId: this.bundleId
    }
  },
  computed: {
    ...mapState('productDetails', ['availablePlugins']),
    bundles () {
      return this.availablePlugins ? this.availablePlugins.bundles : []
    }
  },
  watch: {
    bundleId (val, oldVal) {
      if (val !== oldVal) {
        this.selectedBundleId = val
      }
    }
  },
  methods: {
    onBundleChange (selectedBundleId) {
      this.$emit('bundle-change', selectedBundleId)
    }
  }
}
</script>
